
















































































import { Component, Mixins, Prop } from "vue-property-decorator";
import DataTable from "@/components/data-table/DataTable.vue";
import { ITableHeader, TableQuery } from "@/components/data-table/types";
import DialogMixin from "@/mixins/DialogMixin";
import DictionaryItemForm from "@/components/dictionaries/DictionaryItemForm.vue";
import Industry, { IndustryType } from "@/entity/Industry";
import { merge } from "lodash";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import User from "@/entity/User";
import UserService from "@/services/UserService";
import IndustryService from "@/services/IndustryService";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import Rating from "@/components/Rating.vue";
import SubIndustryService from "@/services/SubIndustryService";
import { Action } from "vuex-class";

@Component({
  components: {
    DataTable,
    DictionaryItemForm,
    ActionButtonWithTooltip,
    Rating,
  },
})
export default class DictionariesView extends Mixins(
  DialogMixin,
  TranslatedEnumListsMixin
) {
  @Prop({ required: true, type: Number }) industryType!: IndustryType;
  @Action("fetchSubIndustries", { namespace: "selectOptions" })
  fetchSubIndustries!: () => Promise<void>;

  showSkeleton = true;
  options!: TableQuery;
  industries: Industry[] = [];
  itemsLength = 0;
  isFetchingItems = false;
  authorOptions: User[] = [];

  get tableHeaders(): ITableHeader[] {
    return [
      {
        filterType: "text",
        text: this.$tc("name", 1),
        value: "name",
        width: "150px",
      },
      {
        text: this.$tc("rating", 1),
        value: "industryRating",
        width: "80px",
        sortable: false,
      },
      // {
      //   filterType: "text",
      //   text: this.$tc("alias", 2),
      //   value: "aliases",
      //   width: "150px",
      // },
      {
        filterType: "select",
        value: "isDeleted",
        filterValue: "isDeleted",
        text: this.$tc("isDeleted", 1),
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        width: "150px",
      },
      {
        filterType: "date",
        text: this.$tc("date", 1),
        value: "updatedAt",
        width: "150px",
      },
      {
        filterType: "select",
        text: this.$tc("author", 1),
        options: this.authorOptions,
        optionText: "displayName",
        optionValue: "id",
        value: "createdBy",
        filterValue: "createdBy",
        width: "150px",
      },
      {
        text: this.$tc("actions"),
        value: "action",
        width: "80px",
      },
    ];
  }

  get headers(): ITableHeader[] {
    const tableHeaders = this.tableHeaders;
    if (this.isSubIndustry) {
      tableHeaders.splice(1, 1);
    }
    return tableHeaders;
  }

  async fetchAuthors(): Promise<void> {
    this.authorOptions = await UserService.findAll();
  }

  async fetchItems() {
    try {
      this.isFetchingItems = true;
      const response = await (this.isSubIndustry
        ? SubIndustryService.findFull(this.options)
        : IndustryService.find(
            merge({}, this.options, {
              filterBy: { branchType: this.industryType },
            })
          ));

      this.industries = response.content;
      this.itemsLength = response.totalItems;
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.projectsList"));
    } finally {
      this.isFetchingItems = false;
    }
  }

  onOptionsChange(options: TableQuery) {
    this.options = options;
    this.fetchItems();
  }

  onFormSuccess(): void {
    this.closeDialog();
    this.fetchItems();
  }

  get tableName(): string | null {
    if (this.industryType == IndustryType.INDUSTRY) {
      return "dictionariesBranches";
    } else if (this.industryType == IndustryType.SUB_INDUSTRY) {
      return "dictionariesSubBranches";
    } else if (this.industryType == IndustryType.FUNCTIONAL_EXPERIENCE) {
      return "dictionariesFunctionalExperience";
    }

    return null;
  }

  get newItemButtonText(): string | null {
    if (this.industryType == IndustryType.INDUSTRY) {
      return this.$tc("newIndustry", 1);
    } else if (this.industryType == IndustryType.SUB_INDUSTRY) {
      return this.$tc("newSubIndustry", 1);
    } else if (this.industryType == IndustryType.FUNCTIONAL_EXPERIENCE) {
      return this.$tc("newFunctionalExperience", 1);
    }

    return null;
  }

  async onIndustryDelete(item: Industry) {
    const didConfirm = await this.$confirm(this.$tc("confirmations.delete"));

    if (!didConfirm) {
      return;
    }
    await (this.isSubIndustry
      ? SubIndustryService
      : IndustryService
    ).deactivate(item.id as number);

    await this.fetchItems();
  }

  async onIndustryActivate(item: Industry) {
    await (this.isSubIndustry
      ? SubIndustryService
      : IndustryService
    ).activateMany([item.id as number]);
    await this.fetchItems();
  }

  async created() {
    await this.fetchAuthors();
    this.showSkeleton = false;
  }

  get isSubIndustry() {
    return this.industryType === IndustryType.SUB_INDUSTRY;
  }

  async destroyed() {
    if (this.isSubIndustry) {
      this.fetchSubIndustries();
    }
  }
}
