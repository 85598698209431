







































import { Component, Prop, Mixins } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import { IndustryType, UpdateIndustry } from "@/entity/Industry";
import BaseForm from "@/components/form/BaseForm";
import IndustryService from "@/services/IndustryService";
import RulesMixin from "@/mixins/RulesMixin";
import DictionaryAliasInput from "@/components/dictionaries/DictionaryAliasInput.vue";
import { Action } from "vuex-class";
import SubIndustryService from "@/services/SubIndustryService";

@Component({
  components: {
    FormCard,
    DictionaryAliasInput,
  },
})
export default class DictionaryItemForm extends Mixins(BaseForm, RulesMixin) {
  @Prop({ required: true, type: Number }) industryType!: IndustryType;

  @Action("fetchIndustries", { namespace: "selectOptions" })
  fetchIndustries!: () => Promise<void>;

  data: UpdateIndustry = {
    name: "",
    aliases: [],
    description: "",
  };

  async fetchIndustry(): Promise<void> {
    if (this.entityId) {
      const response = await (this.isSubIndustry
        ? SubIndustryService
        : IndustryService
      ).findOne(this.entityId);
      this.data.name = response.name;
      this.data.aliases = response.aliases ?? [];
      this.data.description = response.description;
    }
  }

  async submitForm(): Promise<void> {
    const aliases = this.data.aliases;
    const data = {
      ...this.data,
      aliases,
      branchType: this.industryType,
    };

    if (this.entityId) {
      await (this.isSubIndustry ? SubIndustryService : IndustryService).update(
        this.entityId,
        data
      );
    } else {
      await (this.isSubIndustry ? SubIndustryService : IndustryService).create(
        data
      );
    }

    this.fetchIndustries();
  }

  get title(): string | null {
    if (this.industryType == IndustryType.INDUSTRY) {
      return this.$tc("industry", 1);
    } else if (this.industryType == IndustryType.SUB_INDUSTRY) {
      return this.$tc("subIndustry", 1);
    } else if (this.industryType == IndustryType.FUNCTIONAL_EXPERIENCE) {
      return this.$tc("functionalExperience", 1);
    }

    return null;
  }

  get isSubIndustry() {
    return this.industryType === IndustryType.SUB_INDUSTRY;
  }

  created() {
    this.handleFormLoad(this.fetchIndustry);
  }
}
