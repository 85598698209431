import Axios from "axios";
import { ListResponseEntity } from "@/services/types";
import { TableQuery } from "@/components/data-table/types";
import filterQueryParams from "@/services/filterQueryParams";
import Industry, { CreateIndustry, UpdateIndustry } from "@/entity/Industry";

export default class SubIndustryService {
  public static async findFull(
    query?: TableQuery
  ): Promise<ListResponseEntity<Industry>> {
    const response = await Axios.get("api/v2/dictionaries/subIndustries-full", {
      params: {
        ...filterQueryParams(query),
      },
    });
    return response.data;
  }

  public static async findOne(industryId: number): Promise<Industry> {
    const response = await Axios.get(
      `api/v2/dictionaries/subIndustries/${industryId}`
    );
    return response.data;
  }

  public static async create(data: CreateIndustry): Promise<Industry> {
    const response = await Axios.post(
      "api/v2/dictionaries/subIndustries",
      data
    );
    return response.data;
  }

  public static async update(
    industryId: number,
    data: UpdateIndustry
  ): Promise<Industry> {
    const response = await Axios.put(
      `api/v2/dictionaries/subIndustries/${industryId}`,
      data
    );
    return response.data;
  }

  public static async deactivate(industryId: number): Promise<Industry> {
    const response = await Axios.delete(
      `api/v2/dictionaries/subIndustries/${industryId}`
    );
    return response.data;
  }

  public static async activateMany(industryIds: number[]): Promise<void> {
    const response = await Axios.post(
      `api/v2/dictionaries/subIndustries/restore`,
      {},
      {
        params: {
          ids: industryIds.toString(),
        },
      }
    );
    return response.data;
  }
}
