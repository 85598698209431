var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-skeleton-loader',{attrs:{"type":"table","transition":'fade-transition',"loading":_vm.showSkeleton}},[_c('transition-group',[_c('DataTable',{key:"table",attrs:{"headers":_vm.headers,"name":_vm.tableName,"loading":_vm.isFetchingItems,"items-length":_vm.itemsLength,"items":_vm.industries,"has-add-button":true,"add-button-text":_vm.newItemButtonText,"clickable":true},on:{"optionsChanged":_vm.onOptionsChange,"addItemClicked":function($event){return _vm.openDialog('industryForm')},"rowClicked":function($event){return _vm.openDialog('industryForm', $event.id)}},scopedSlots:_vm._u([{key:"item.aliases",fn:function(ref){
var item = ref.item;
return [(item.aliases === null || item.aliases.length === 0)?_c('span'):_vm._l((item.aliases),function(alias,index){return _c('v-chip',{key:index,staticClass:"mb-2",staticStyle:{"margin":"4px 8px 4px 0"}},[_vm._v(" "+_vm._s(alias)+" ")])})]}},{key:"item.industryRating",fn:function(ref){
var item = ref.item;
return [_c('Rating',{attrs:{"value":item.industryRating}})]}},{key:"item.isDeleted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("booleanTextValue")((item.deletedAt !== null)))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatUnixTimestamp")((item.updatedAt || item.createdAt)))+" ")]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.displayName)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.deletedAt === null)?_c('ActionButtonWithTooltip',{attrs:{"tooltip":_vm.$t('archive'),"icon":"mdi-archive"},on:{"click":function($event){return _vm.onIndustryDelete(item)}}}):_c('ActionButtonWithTooltip',{attrs:{"tooltip":_vm.$t('unarchive'),"icon":"mdi-package-up"},on:{"click":function($event){return _vm.onIndustryActivate(item)}}})]}}])}),_c('DialogWrapper',{key:"dialog",attrs:{"value":_vm.isDialogOpened}},[(_vm.activeDialog === 'industryForm')?_c('DictionaryItemForm',{attrs:{"entity-id":_vm.dialogEntityId,"industry-type":_vm.industryType},on:{"cancel":_vm.closeDialog,"success":_vm.onFormSuccess}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }